import ToastLogo from '../images/toastLogoSmall.png';
import HelloLogo from '../images/helloLogo.png';
import LuvyaLogo from '../images/luvya.png';
import OrderCraftLogo from '../images/orderCraftLOGO.png';
import AstraLogo from '../images/sentinet6.png';
import ConfirmitaLogo from '../images/conformita.png'
import PhoneLogo from '../images/phoneIcon.png'
import ScrollableList from './ScrollableList';

const products = [
    {
        title: "getTOAST.ai",
        desc: "Plug-n-play Chat GPT API replacement. Start saving 50% or more in 15 minutes or less.",
        image: ToastLogo,
        bgColor: 'bg-skyblue',
        link: 'https://www.gettoast.ai/',
        otherLinks: [
            {
                link: 'https://www.gettoast.ai/',
                title: 'Visit Website'
            }
        ]
    }, 
    {
        title: "getHELLO.ai",
        desc: "Unlimited 5-star customer service and sales reps at a touch of a button. Closed loop, outbound & inbound.",
        image: HelloLogo,
        bgColor: 'bg-black',
        link: 'https://www.gethello.ai/',
        otherLinks: [
            {
                link: 'https://getHELLO.ai/',
                title: 'Visit Website'
            }
        ]

    }, {
        title: "Luvya",
        desc: "Wardrobe anyone ? Instantly create outfits and see how they will fit based on your exact body measurements.",
        image: LuvyaLogo,
        bgColor: 'bg-blue',
        link: 'https://www.gethello.ai/',
        otherLinks: [
        ]
    },
    {
        title: "OrderCraft.ai",
        desc: "Most accurate & friendly universal voice ordering system.  Gen ai for drive-thru, counter, apps, airports!  Multi-lingual.",
        image: OrderCraftLogo,
        bgColor: 'bg-black',
        link: 'https://calendly.com/imavailable/5starcustomerserviceai',
        otherLinks: [
            {
                title: 'Get a Demo',
                link: 'https://calendly.com/y2a/meeting'
            }
        ]
    }, 
    {
        title: "Meet Astra",
        desc: "Automatically have your meetings coordinated and calendared. \"Astra, find time for us to all meet next week.\"",
        image: AstraLogo,
        bgColor: 'bg-white',
        link: 'https://www.gettoast.ai/',
        otherLinks: [
        ]
    }, 
    {
        title: "Conformita.ai",
        desc: "Compliance and regulation automatic summaries and ai generated workflow, approvals & implementation.",
        image: ConfirmitaLogo,
        bgColor: 'bg-[#002060]',
        link: 'https://www.gettoast.ai/',
        otherLinks: [
        ]
    }, 
    {
        title: "Schedule a Meeting Now",
        desc: "Book a demo. An immersive way to experience generative ai. Book now.",
        image: PhoneLogo,
        bgColor: 'bg-black',
        link: 'https://calendly.com/y2a/meeting',
        otherLinks: [
            {
                title: 'Meet With Us',
                link: 'https://calendly.com/y2a/meeting?'
            }
        ]
    }, 
]

const FeaturedProducts = () => {
    return (
        <ScrollableList 
            products={products}
            arrow_color={'fill-slate-500'}
            renderItem={(item, idx) => (
                <div className="snap-start snap-always rounded-lg bg-white mr-4 md:mr-10 sm:min-w-80 min-w-[calc(100%-2rem)]" key={idx}>
                    <div className={`flex justify-around items-center p-2 ${item.bgColor} rounded-t-lg h-42`}>
                        <img src={item.image} alt="" style={{height: '112px'}} />
                    </div>
                    <div className="py-5 px-4 border-t font-normal text-black flex flex-col justify-between" style={{height: '170px'}}>
                        <div>
                            <h3 className="text-xl">{item.title}</h3>
                            <p className="text-grey pb-4">{item.desc}</p>
                        </div>
                        <div className='flex justify-center'>
                            {
                                item.otherLinks.map(({ link, title }) => {
                                    return (
                                        <a href={link} className="underline text-yellow hover:text-black block mr-3" target='_blank'>
                                            {title}
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        />
    );
};

export default FeaturedProducts;