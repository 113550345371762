import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import Footer from './components/Footer';

export default function About() {
  return (
    <div className='h-full'>
      <NavBar />
      <Carousel  pb='0'>
        <Hero />
      </Carousel>
        <Carousel bgColor="bg-slate" title={<h1 className="text-black pb-4 px-4 text-2xl font-medium uppercase"><span className='tracking-wide'>About Y2A:</span> <span className='font-normal normal-case'>Ready-to-use, self-serve, ai-as-a-service™ subscriptions.</span></h1>}>
          <div className='px-4'>
          <div className="text-xl">
            Just like Google came along years after AOL and Netscape to make the internet accessible by launching
            easy-to-use products; Y2A has come along to do the same for the world with generative
             AI.
            </div>
            <div className='text-xl pt-4 pb-2'>
            Y2A easy-to-use, ai-as-a-service products for small through Enterprise customers.
            Our Enterprise group, “the Agency” comes alongside the C-Suite and directors to ensure that ai scales and supports well-established use cases.
            </div>
          </div>
        </Carousel>
        <Footer />
    </div>
  );
}