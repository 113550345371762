import QuoteImage from '../images/TRACY-Quote_April.svg';

const Button = ({ title, bgColor }) => {
    return (
        <a
            className={`px-6 py-2 text-white hover:text-black ${bgColor} text-2xl rounded-lg duration-150 font-bold uppercase inline align-[8px]`}
            href="https://gethello.ai"
            target="_blank"
            rel="noopener noreferrer"
        >
        <span>{ title }</span>
      </a>
    )
}

export default () => {
    return (
        <section className="flex justify-between md:px-4">
            <div className="text-black overflow-hidden md:flex justify-between items-center">
                <div className='md:text-4xl text-3xl font-normal'>
                    <div className='pb-4'>Instantly try, create and launch generative AI.</div>
                    <Button title="Try AI now" bgColor="bg-green" />
                </div>
                <div className='lg:block hidden h-64'>
                    <img src={QuoteImage} className='h-64' />
                </div>
            </div>
        </section>
    )
}