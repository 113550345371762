import { useState } from 'react';

const ScrollableList2 = ({ products: initialProducts, renderItem, arrow_color }) => {
    const [products, setProducts] = useState(initialProducts);

    const scrollLeft = () => {
        // Move the last item to the beginning
        setProducts(prevProducts => [prevProducts[prevProducts.length - 1], ...prevProducts.slice(0, -1)]);
    };

    const scrollRight = () => {
        // Move the first item to the end
        setProducts(prevProducts => [...prevProducts.slice(1), prevProducts[0]]);
    };

    return (
        <div className="relative mx-auto max-w-6xl pr-4">
            {/* The scrollable container */}
            <div className="mx-auto w-full overflow-x-scroll  overflow-y-none snap-x snap-mandatory" style={{scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                <div className={`flex snap-center overflow-y-none`}>
                    {products.map((item, idx) => renderItem(item, idx))}
                </div>
            </div>
        </div>
    );
};



const ScrollableList = ({ products: initialProducts, renderItem, arrow_color }) => {
    const [products, setProducts] = useState(initialProducts);

    const scrollLeft = () => {
        // Move the last item to the beginning
        setProducts(prevProducts => [prevProducts[prevProducts.length - 1], ...prevProducts.slice(0, -1)]);
    };

    const scrollRight = () => {
        // Move the first item to the end
        setProducts(prevProducts => [...prevProducts.slice(1), prevProducts[0]]);
    };

    return (
        <div className="relative mx-auto px-4">
            {/* Left arrow */}
            <button onClick={scrollLeft} className="absolute left-0 top-1/2 z-10 hidden md:block" style={{ transform: 'translateX(-175%)' }}>
                {/* Use an arrow icon or custom design */}
                <svg xmlns="http://www.w3.org/2000/svg" className={`w-10 ${arrow_color}`} viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            </button>
            
            {/* The scrollable container */}
            <div className="mx-auto w-full overflow-x-scroll overflow-y-none snap-x snap-mandatory" style={{scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                <div className={`flex snap-center overflow-y-none`}>
                    {products.map((item, idx) => renderItem(item, idx))}
                </div>
            </div>
            
            <button onClick={scrollRight} className="md:absolute relative right-0 top-1/2 z-10 hidden md:block" style={{ transform: 'translateX(175%)' }}>
                {/* Use an arrow icon or custom design */}
                <svg xmlns="http://www.w3.org/2000/svg" className={`w-10 ${arrow_color}`} viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </button>
        </div>
    );
};

export default ScrollableList;