import ScrollableList from './ScrollableList';

import { ReactComponent as BankLogo } from '../images/bankWhite.svg';
import { ReactComponent as DriveLogo } from '../images/drveThruWhite.svg';
import BankAudio from '../images/inbound.wav';
import McdonaldsAudio from '../images/mcdonalds.wav';
import { ReactComponent as ComplianceLogo } from '../images/complianceBlack.svg';
import { ReactComponent as CalendarLogo } from '../images/calendar-regular.svg';
const products = [
    
    {
        title: "Bank Keeps a Client",
        desc: "Our gen ai call center representative saves this bank's credit card customer.",
        image: BankLogo,
        bgColor: 'bg-slate-400',
        link: BankAudio,
        otherLinks: [
        ]
    }, {
        title: "Drive-Thru Short Staff",
        desc: "Short staffed drive-thru \"hires\" friendly AI workers that upsell & get it right!",
        image: DriveLogo,
        bgColor: 'bg-black',
        link: McdonaldsAudio,
        otherLinks: [
        ]

    }, 
    {
        title: "Custom LLM",
        desc: "Exlore how a custom LLM saved a Silicon Valley's hot new company.",
        image: ComplianceLogo,
        bgColor: 'bg-black',
        otherLinks: [
            {
                title: 'Learn More',
                link: 'https://gettoast.ai/case-study-enterprise'
            }
        ]
    },
    {
        title: "Schedule Meeting",
        desc: "Experience ai. Schedule a meeting with us. Keep in mind you are using our ai!",
        image: CalendarLogo,
        bgColor: 'bg-black',
        otherLinks: [
            {
                title: 'Meet With Us',
                link: 'https://calendly.com/y2a/meeting?/30min'
            }
        ]
    },

]

const TrendingProjects = () => {
    return (
        <ScrollableList 
            products={products}
            arrow_color={'fill-slate-500'}
            renderItem={(item, idx) => (
                <div className="snap-start snap-always rounded-lg bg-white mr-4 md:mr-10 sm:min-w-80 min-w-[calc(100%-2rem)] flex flex-col justify-between h-80" key={idx}>
                    <div>
                        <div className={`flex items-start justify-between items-center p-4 bg-[#6b7280] rounded-t-lg min-h-32`}>
                            <h2 className="text-3xl text-white font-medium mr-8">{item.title}</h2>
                            {
                                item.image ? (
                                    <item.image className="fill-white h-16" />
                                )
                                :
                                null
                            }
                        </div>
                        <div className="py-5 px-4 border-t pt">
                            <p className="text-grey font-normal text-base">{item.desc}</p>
                            
                        </div>
                    </div>
                    <div className='flex justify-center pb-4'>
                    {item.link ? <audio className='w-full px-2' controls src={item.link}></audio> : null}
                        {
                            item.otherLinks.map(({ link, title }) => {
                                return (
                                    <a href={link} className="underline align-center pb-4 text-yellow hover:text-black block mr-3" target='_blank'>
                                        {title}
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        />
    );
};

export default TrendingProjects;