import NavBar from './components/NavBar';
import Hero from './components/Hero';
import FeaturedProducts from './components/FeaturedProducts';
import PrototypeProjects from './components/PrototypeProjects';
import TrendingProjects from './components/TrendingProjects';
import Carousel from './components/Carousel';
import Footer from './components/Footer';

function App() {
  return (
        <div className='w-full'>
          <NavBar />
              <Carousel pb='0'>
                <Hero />
              </Carousel>
              <Carousel bgColor="bg-slate" title={<div className="text-black text-2xl px-4 font-medium uppercase text-center pb-4"><div className='tracking-wide'>FEATURED PRODUCTS:</div><div className='font-normal md:inline normal-case leading-tight'>Ready-to-use, self-serve, ai-as-a-service<sup className='text-sm'>TM</sup> subscriptions.</div></div>}>
                <FeaturedProducts />
              </Carousel>
              <Carousel bgColor="bg-black">
                <h1 className="text-white text-2xl px-4 font-medium uppercase text-center pb-4"><span className='tracking-wide'>CLICK AND PLAY AI<sup className='text-sm'>TM</sup>:</span> <div className='text-yellow  md:inline font-normal normal-case'>Build Your Own Prototype Now.</div></h1>
                <PrototypeProjects />
              </Carousel>
              <Carousel bgColor="bg-slate">
                <h1 className="text-black text-2xl px-4 font-medium uppercase tracking-wide text-center pb-4"><span className='tracking-wide'>Trending Projects:</span><div className='font-normal  md:inline normal-case'>Community Showcase</div></h1>
                <TrendingProjects />
              </Carousel>
              <Footer />
        </div>
  );
}
// Fix the spacing between the header and the title
export default App;