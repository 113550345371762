import { useState } from "react"
import logo from '../images/Y2A_300x220_whiteYellow.png'

export default () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  const [menuState, setMenuState] = useState(false)

    // Replace javascript:void(0) path with your path
    const navigation = [
        { title: "Our Lab", path: "https://labs.y2a.ai" },
        { title: "About Us", path: "/about" },
    ]
    return (
        <nav className="bg-black border-b sticky py-4 top-0 z-50">
            <div className="max-w-6xl mx-auto px-4 flex items-center justify-between">
                <a className="" href="/">
                    <img
                        src={logo}
                        alt="2YFV logo"
                        className="h-16"
                    />
                </a>
                <div className="flex space-x-4">
                    <a href="https://calendly.com/y2a/meeting" target="_blank" rel="noopener noreferrer" className="px-4 md:order-last py-2 ml-2 text-center text-white hover:text-black bg-yellow rounded-md duration-150 hover:bg-yellow font-extrabold uppercase">Meet With Us</a>
                    <div className={`flex md:flex-row flex-col md:items-center px-4 md:p-0 hidden md:block`}>
                        {
                            navigation.map((item, idx) => (
                                <div className="ml-auto inline-flex">
                                    <a key={idx} href={item.path} class="text-white mx-2 p-2 font-normal hover:text-yellow" target={item.path === 'https://labs.y2a.ai' ? '_blank': '_parent'}>
                                        {item.title}
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                    <button onClick={toggleMenu}  className="md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className={`flex md:flex-row flex-col md:items-center md:hidden px-4 md:p-0 ${isOpen ? 'hidden' : 'block'}`}>
                        {
                            navigation.map((item, idx) => (
                                <div className="ml-auto inline-flex">
                                    <a key={idx} href={item.path} class="text-white mx-2 p-2 font-normal hover:text-yellow" target={item.path === 'https://labs.y2a.ai' ? '_blank': '_parent'}>
                                        {item.title}
                                    </a>
                                </div>
                            ))
                        }
                    </div>
        </nav>
    )
}