
const Carousel = ({ children, bgColor, title, pb="pb-8" }) => {
    return (
        <section className={`${pb} md:px-20 px-4`}>
            <div className={`${bgColor} rounded-md py-5`}>
                <div className="max-w-6xl mx-auto">
                    <div className="mx-auto">
                        { title }
                        { children }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Carousel