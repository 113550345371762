import logo from '../images/Y2A_300x220_blackYellow.png';

export default () => {
    return (
        <div id="footer" className='flex space-x-2 align-middle justify-center items-center md:px-2 py-4 px-4 mx-auto container'>
        <img
        src={logo}
        alt="2YFV logo"
        className="h-8"
        />
        <a className='text-yellow text-lg' href='/about'>About Us</a> <span>/</span>
        <a className='text-yellow text-lg' href='https://labs.y2a.ai'>Our Lab</a> <span>/</span>
        <a className='text-yellow text-lg' href='/contact'>Contact Us</a>
        </div>
    )
}