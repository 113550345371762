import  { useRef } from 'react';

import EngineLogo from '../images/engine.png';
import ToastLogo from '../images/toastLogoSmall.png';
import WatchLogo from '../images/watch.png';
import PhoneLogo from '../images/phoneIcon.png';
import ScrollableList from './ScrollableList'; // Import the ScrollableList component
const products = [
    {
        title: "VOICE AI",
        desc: "Build your own voice ai customer service or sales reps.",
        image: WatchLogo,
        bgColor: 'bg-yellow',
        otherLinks: [
            {
                link: 'https://gethello.ai/',
                title: 'Try Now'
            }
        ]

    }, {
        title: "Data Engine",
        desc: "Upload your data or tell us what data you have. We'll look at it, clean it and tell you what magic we can we do with it.",
        image: EngineLogo,
        bgColor: 'bg-yellow',
        otherLinks: [
            {
                link: 'https://docs.google.com/forms/d/e/1FAIpQLSd1C3RW0Pd7guzcvCetdgOOrLfh757rcTBFFEGxSOk2HBrGVA/viewform',
                title: 'Get Started'
            }
        ]
    }, 
    {
        title: "getTOAST",
        desc: "Plug-n-play Chat GPT API replacement. Start saving 50% in 15 minutes or less. Click & play savings calculator.",
        image: ToastLogo,
        bgColor: 'bg-yellow',
        otherLinks: [
            {
                link: 'https://gettoast.ai/how-easy-is-it',
                title: 'How it works'
            },
        ]
    },
    {
        title: "Schedule a Meeting",
        desc: "Book a demo. An immersive way to experience generative ai. Book now.",
        image: PhoneLogo,
        bgColor: 'bg-yellow',
        otherLinks: [
            {
                title: 'Meet With Us',
                link: 'https://calendly.com/y2a/meeting?/30min'
            }
        ]
    }
]


const PrototypeProjects = () => {
    return (
        <ScrollableList 
            products={products}
            arrow_color={'fill-slate-500'}
            renderItem={(item, idx) => (
                <div className="snap-start snap-always rounded-lg bg-white mr-4 md:mr-10 sm:min-w-80 min-w-[calc(100%-2rem)]" key={idx}>
                    <div className={`flex items-start justify-between items-center p-4 ${item.bgColor} rounded-t-lg min-h-32`}>
                        <h2 className="text-3xl text-white font-medium mr-8">{item.title}</h2>
                        {
                            item.image ? (
                                <img src={item.image} className='h-28' />
                            )
                            :
                            null
                        }
                    </div>
                    <div className="py-5 px-4 border-t flex flex-col justify-between" style={{height: "170px"}}>
                        <div>
                            <p className="text-grey font-normal text-base">{item.desc}</p>
                        </div>
                        <div className='flex justify-center'>
                            {
                                item.otherLinks.map(({ link, title }) => {
                                    return (
                                        <a href={link} className="underline text-yellow block mr-3" target='_blank'>
                                            {title}
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        />
    );
};

export default PrototypeProjects;