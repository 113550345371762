import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import Footer from './components/Footer';

export default function About() {
  return (
    <div className='h-full'>
      <NavBar />
      <Carousel  pb='0'>
        <Hero />
      </Carousel>
      <h1 className='w-full mx-auto max-w-6xl px-4 text-2xl'>Contact Us</h1>
      <div className='bg-slate h-full pt-4 pb-16'>
        <div className='max-w-6xl mx-auto'>
        <form action='https://formcarry.com/s/Zzo58JZ-vRI' method="POST" enctype="multipart/form-data" class="grid md:grid-cols-6 grid-cols-2 px-4 max-w-3xl items-center w-full justify-center align-center gap-4">
          <div className="md:col-span-3 col-span-6 w-full">
            <label for="firstname">First Name:</label><br />
            <input type="text" id="firstname" placeholder='Your first name' className="rounded-lg px-2 p-1 w-full outline-grey" name="firstname" />
          </div>
          <div className="md:col-span-3 col-span-6">
            <label for="lastname">Last Name:</label><br />
            <input type="text" id="lastname" placeholder="Your last name" className="rounded-lg px-2 p-1 w-full outline-grey" name="lastname" />
          </div>
          <div className="md:col-span-6 col-span-6">
            <label for="email">Email Address:</label><br />
            <input type="email" id="email" placeholder='john@doe.com' className="rounded-lg px-2 p-1 w-full outline-grey" name="email" />
          </div>
          <div className="col-span-6">
            <label for="message">Message:</label><br />
            <textarea type="text" rows="4" id="message" placeholder='Enter your message...' className="rounded-lg px-2 p-1 w-full outline-grey" name="message" />
          </div>
          <button className='bg-yellow text-bold w-32 p-1 rounded-lg text-white hover:text-black'>
            Submit
          </button>
        </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}